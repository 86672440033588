<template>
    <div class="row justify-content-center my-3">
        <div class="col-md-3">
            <div class="card d-none d-md-block">
                <div class="list-group list-group-flush">
                    <h5 class="p-3 text-center">Les étapes de mon inscription</h5>
                    <a class="list-group-item border-0 d-flex justify-content-between align-items-baseline text-decoration-none" :class="{'disabled': 1 > lastStep, 'active': step === 1}" href="#" @click.prevent="setStep(1)"><span>Qui suis-je ?</span> <i v-show="lastStep > 1" class="fa-solid fa-check"></i></a>
                    <a class="list-group-item border-0 d-flex justify-content-between align-items-baseline text-decoration-none" :class="{'disabled': 2 > lastStep, 'active': step === 2}" href="#" @click.prevent="setStep(2)"><span>Mon agence</span><i v-show="lastStep > 2" class="fa-solid fa-check"></i></a>
                    <a class="list-group-item border-0 d-flex justify-content-between align-items-baseline text-decoration-none" :class="{'disabled': 3 > lastStep, 'active': step === 3}" href="#" @click.prevent="setStep(3)"><span>Mes informations personnelles</span><i v-show="lastStep > 3" class="fa-solid fa-check"></i></a>
                    <a class="list-group-item border-0 d-flex justify-content-between align-items-baseline text-decoration-none" :class="{'disabled': 4 > lastStep, 'active': step === 4}" href="#" @click.prevent="setStep(4)"><span>Ma situation personnelle</span><i v-show="lastStep > 4" class="fa-solid fa-check"></i></a>
                    <a class="list-group-item border-0 d-flex justify-content-between align-items-baseline text-decoration-none" :class="{'disabled': 5 > lastStep, 'active': step === 5}" href="#" @click.prevent="setStep(5)"><span>Mes coordonnées</span><i v-show="lastStep > 5" class="fa-solid fa-check"></i></a>
                    <a class="list-group-item border-0 d-flex justify-content-between align-items-baseline text-decoration-none" :class="{'disabled': 6 > lastStep, 'active': step === 6}" href="#" @click.prevent="setStep(6)"><span>Mon profil</span><i v-show="lastStep > 6" class="fa-solid fa-check"></i></a>
                    <a class="list-group-item border-0 d-flex justify-content-between align-items-baseline text-decoration-none" :class="{'disabled': 7 > lastStep, 'active': step === 7}" href="#" @click.prevent="setStep(7)"><span>Mes préférences</span><i v-show="lastStep > 7" class="fa-solid fa-check"></i></a>
                </div>
            </div>
        </div>

        <div class="col-md-6">
            <form @submit.prevent="nextStep()" @keydown.enter.prevent="nextStep">
                <input type="hidden" name="_token" :value="csrf">
                <input type="hidden" name="_method" value="put">
                <div class="card" v-if="step ===1">
                    <div class="card-body">
                        <div class="row">
                            <h3 class="col-12 mt-0">Qui suis-je ?</h3>

                            <div class="form-group col-md-12">
                                <label for="ApplicantGender">
                                    Civilité
                                    <span title="Champ requis">*</span>
                                </label>
                                <select
                                    v-model="applicant.gender"
                                    id="ApplicantGender"
                                    class="form-control"
                                    :class="{'is-invalid': errors.gender}"
                                >
                                    <option value="male">Monsieur</option>
                                    <option value="female">Madame</option>
                                </select>
                                <div class="invalid-feedback" v-text="errors.gender"></div>
                            </div>

                            <div class="form-group col-md-6">
                                <label for="ApplicantLastName">
                                    Nom
                                    <span title="Champ requis">*</span>
                                </label>
                                <input
                                    type="text"
                                    id="ApplicantLastName"
                                    class="form-control"
                                    v-model="applicant.last_name"
                                    required
                                    :class="{'is-invalid': errors.last_name}"
                                >
                                <div class="invalid-feedback" v-text="errors.last_name"></div>
                            </div>

                            <div class="form-group col-md-6">
                                <label for="ApplicantFirstName">
                                    Prénom
                                    <span title="Champ requis">*</span>
                                </label>
                                <input
                                    type="text"
                                    id="ApplicantFirstName"
                                    class="form-control"
                                    v-model="applicant.first_name"
                                    required
                                    :class="{'is-invalid': errors.first_name}"
                                >
                                <div class="invalid-feedback" v-text="errors.first_name"></div>
                            </div>

                            <div class="col-md-12 form-group">
                                <label for="ApplicantDiscoverySource">Comment ai-je connu Ettic ?</label>
                                <v-select
                                    :options="discoverySources"
                                    label="value"
                                    :reduce="discoverySource => discoverySource.value"
                                    :class="{'is-invalid': errors.discovered_us_by}"
                                    v-model="applicant.discovered_us_by"
                                ></v-select>
                                <div class="invalid-feedback" v-text="errors.discovered_us_by"></div>
                            </div>

                            <div class="col-md-12 form-group" v-if="'Autre :' === applicant.discovered_us_by">
                                <label for="DiscoveredUsByOtherCustom">Dites nous en plus</label>
                                <input
                                    class="form-control"
                                    v-model="applicant.discovered_us_by_custom"
                                    id="DiscoveredUsByOtherCustom"
                                    type="text"
                                >
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card" v-if="step === 2">
                    <div class="card-body">
                        <div class="row">
                            <h3 class="col-12 mt-0">Mon agence</h3>
                            <h4 class="col-12 h5 mt-0">Je choisis mes agences <span title="Champ requis">*</span></h4>

                            <div class="d-flex flex-column">
                                <div class="col form-group" v-for="agency in agencies" :key="agency.id">
                                    <div class="custom-control custom-checkbox" :class="{'is-invalid': errors.agency_ids}">
                                        <input
                                            type="checkbox"
                                            v-model="applicant.agency_ids"
                                            :value="agency.id"
                                            :id="'AgencyId-'+agency.id"
                                            class="custom-control-input"
                                            :class="{'is-invalid': errors.agency_ids}"
                                        >
                                        <label :for="'AgencyId-'+agency.id" class="custom-control-label" v-text="agency.name"></label>
                                    </div>
                                </div>
                            </div>

                            <h4 class="col-12 h5">Mes préférences de contrat <span title="Champ requis">*</span></h4>

                            <div>
                                <div class="form-group col-12">
                                    <div class="custom-control custom-radio">
                                        <input
                                            type="radio"
                                            class="custom-control-input"
                                            v-model="applicant.accepted_contracts" value="all"
                                            id="ApplicantAcceptedContracts-all"
                                            :class="{'is-invalid': errors.accepted_contracts}"
                                        >
                                        <label class="custom-control-label" for="ApplicantAcceptedContracts-all">Je souhaite accéder à toutes les offres d'emploi, y compris Contrats de Travail Temporaire (intérim)</label>
                                    </div>
                                </div>

                                <div class="form-group col-12">
                                    <div class="custom-control custom-radio">
                                        <input
                                            type="radio"
                                            class="custom-control-input"
                                            v-model="applicant.accepted_contracts" value="all-but-ctt"
                                            id="ApplicantAcceptedContracts-all-but-ctt"
                                            :class="{'is-invalid': errors.accepted_contracts}"
                                        >
                                        <label class="custom-control-label" for="ApplicantAcceptedContracts-all-but-ctt">Je souhaite accéder aux offres d'emploi à l'exclusion des Contrats de Travail Temporaire (notamment si je suis assujetti·e à la loi Valletoux)</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card" v-if="step === 3">
                    <div class="card-body">
                        <div class="row">
                            <h3 class="col-12 mt-0">Mes informations personnelles</h3>

                            <div class="form-group col-12">
                                <label for="ApplicantMaidenName">
                                    Nom de naissance
                                    <span title="Champ requis" v-if="'all' === applicant.accepted_contracts">*</span>
                                </label>
                                <input
                                    type="text"
                                    id="ApplicantMaidenName"
                                    class="form-control"
                                    v-model="applicant.maiden_name"
                                    :class="{'is-invalid': errors.maiden_name}"
                                >
                                <div class="invalid-feedback" v-text="errors.maiden_name"></div>
                            </div>

                            <div class="form-group col-md-6">
                                <label for="ApplicantBirthdate">
                                    Date de naissance
                                    <span title="Champ requis" v-if="'all' === applicant.accepted_contracts">*</span>
                                </label>
                                <input
                                    type="date"
                                    v-model="applicant.birthdate"
                                    id="ApplicantBirthdate"
                                    class="form-control"
                                    :class="{'is-invalid': errors.birthdate}"
                                >
                                <div class="invalid-feedback" v-text="errors.birthdate"></div>
                            </div>

                            <div class="form-group col-md-6">
                                <label for="ApplicantNationality">
                                    Nationalité
                                    <span title="Champ requis" v-if="'all' === applicant.accepted_contracts">*</span>
                                </label>
                                <v-select
                                    :options="nationalities"
                                    label="inclusive_name"
                                    :reduce="nationality => nationality.id"
                                    v-model="applicant.nationality_id"
                                    :class="{'is-invalid': errors.nationality_id}"
                                ></v-select>
                                <div class="invalid-feedback" v-text="errors.nationality_id"></div>
                            </div>

                            <div class="form-group col-md-6">
                                <label for="ApplicantBirthCountry">
                                    Pays de naissance
                                    <span title="Champ requis" v-if="'all' === applicant.accepted_contracts">*</span>
                                </label>
                                <v-select
                                    :options="countries"
                                    label="name"
                                    :reduce="country => country.id"
                                    v-model="applicant.birth_country_id"
                                    :class="{'is-invalid': errors.birth_country_id}"
                                ></v-select>
                                <div class="invalid-feedback" v-text="errors.birth_country_id" ></div>
                            </div>

                            <div class="form-group col-md-6" v-if="bornInFrance">
                                <label for="ApplicantBirthDepartment">
                                    Département de naissance
                                    <span title="Champ requis" v-if="'all' === applicant.accepted_contracts">*</span>
                                </label>
                                <v-select
                                    :options="departments"
                                    label="full_name"
                                    :reduce="department => department.id"
                                    v-model="applicant.birth_department_id"
                                    :class="{'is-invalid': errors.birth_department_id}"
                                ></v-select>
                                <div class="invalid-feedback" v-text="errors.birth_department_id" ></div>
                            </div>

                            <div class="form-group col-md-6">
                                <label for="ApplicantBirthplace">
                                    Lieu de naissance
                                    <span title="Champ requis" v-if="'all' === applicant.accepted_contracts">*</span>
                                </label>
                                <input
                                    type="text"
                                    v-model="applicant.birthplace"
                                    id="ApplicantBirthplace"
                                    class="form-control"
                                    :class="{'is-invalid': errors.birthplace}"
                                >
                                <div class="invalid-feedback" v-text="errors.birthplace"></div>
                            </div>

                            <div class="form-group col-md-6">
                                <label for="ApplicantSocialSecurityNumber">
                                    N° de sécurité sociale
                                    <span title="Champ requis" v-if="'all' === applicant.accepted_contracts">*</span>
                                </label>
                                <input
                                    type="text"
                                    v-model="applicant.social_security_number"
                                    id="ApplicantSocialSecurityNumber"
                                    class="form-control"
                                    :class="{'is-invalid': errors.social_security_number}"
                                >
                                <div class="invalid-feedback" v-text="errors.social_security_number"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card" v-if="step === 4">
                    <div class="card-body">
                        <div class="row">
                            <h3 class="col-12 mt-0">Ma situation personnelle</h3>

                            <div class="form-group col-md-6">
                                <label for="ApplicantSituation">
                                    Situation familiale
                                    <span title="Champ requis" v-if="'all' === applicant.accepted_contracts">*</span>
                                </label>
                                <select
                                    v-model="applicant.situation"
                                    id="ApplicantSituation"
                                    class="form-control"
                                    :class="{'is-invalid': errors.situation}"
                                >
                                    <option
                                        v-for="(situation, key) in situations"
                                        v-text="situation"
                                        :value="key"
                                    ></option>
                                </select>
                                <div class="invalid-feedback" v-text="errors.situation"></div>
                            </div>

                            <div class="form-group col-md-6">
                                <label for="ApplicantDependantChildren">
                                    Nombre d'enfants à charge
                                    <span title="Champ requis" v-if="'all' === applicant.accepted_contracts">*</span>
                                </label>
                                <input
                                    type="number"
                                    min="0"
                                    max="100"
                                    v-model="applicant.dependant_children"
                                    id="ApplicantDependantChildren"
                                    class="form-control"
                                    :class="{'is-invalid': errors.dependant_children}"
                                >
                                <div class="invalid-feedback" v-text="errors.dependant_children"></div>
                            </div>

                            <div class="form-group col-md-6">
                                <div class="custom-control custom-switch pb-2 pt-3">
                                    <input
                                        type="checkbox"
                                        class="custom-control-input"
                                        v-model="applicant.disabled_worker_status"
                                        value="1"
                                        id="ApplicantDisabledStatus"
                                    >
                                    <label for="ApplicantDisabledStatus" class="custom-control-label col-form-label">Statut de travailleur handicapé</label>

                                    <div class="invalid-feedback pb-3" v-text="errors.disabled_worker_status"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card" v-if="step === 5">
                    <div class="card-body">
                        <div class="row">
                            <h3 class="col-12 mt-0">Mes coordonnées</h3>

                            <div class="form-group col-md-6">
                                <label for="ApplicantAddressLine1">
                                    Adresse
                                    <span title="Champ requis" v-if="'all' === applicant.accepted_contracts">*</span>
                                </label>
                                <input
                                    type="text"
                                    v-model="applicant.address_line_1"
                                    id="ApplicantAddressLine1"
                                    class="form-control"
                                    :class="{'is-invalid': errors.address_line_1}"
                                >
                                <div class="invalid-feedback" v-text="errors.address_line_1"></div>
                            </div>

                            <div class="form-group col-md-6">
                                <label for="ApplicantAddressLine2">Complément d'adresse</label>
                                <input
                                    type="text"
                                    v-model="applicant.address_line_2"
                                    id="ApplicantAddressLine2"
                                    class="form-control"
                                    :class="{'is-invalid': errors.address_line_2}"
                                >
                                <div class="invalid-feedback" v-text="errors.address_line_2"></div>
                            </div>

                            <div class="form-group col-md-6">
                                <label for="ApplicantAddressCityId">
                                    Ville
                                    <span title="Champ requis" v-if="'all' === applicant.accepted_contracts">*</span>
                                </label>
                                <v-select
                                    @search="onCitySearch"
                                    v-model="applicant.address_city_id"
                                    :options="cities"
                                    :filterable="false"
                                    label="text"
                                    :reduce="country => country.id"
                                    :class="{'is-invalid': errors.address_city_id}"
                                >
                                    <template #no-options="{search, searching, loading}">Je tape le nom de la ville ou son code postal.</template>
                                </v-select>
                                <div class="invalid-feedback" v-text="errors.address_city_id"></div>
                            </div>

                            <div class="form-group col-md-6">
                                <label for="ApplicantPhone">
                                    Téléphone
                                    <span title="Champ requis">*</span>
                                </label>
                                <input
                                    type="tel"
                                    v-model="applicant.phone"
                                    id="ApplicantPhone"
                                    class="form-control"
                                    :class="{'is-invalid': errors.phone}"
                                >
                                <div class="invalid-feedback" v-text="errors.phone"></div>
                            </div>

                            <h4 class="col-12">Contact d'urgence</h4>

                            <div class="form-group col-md-6">
                                <label for="ApplicantEmergencyContactName">
                                    Nom de la personne à contacter
                                    <span title="Champ requis" v-if="'all' === applicant.accepted_contracts">*</span>
                                </label>
                                <input
                                    type="text"
                                    v-model="applicant.emergency_contact_name"
                                    id="ApplicantEmergencyContactName"
                                    class="form-control"
                                    :class="{'is-invalid': errors.emergency_contact_name}"
                                >
                                <div class="invalid-feedback" v-text="errors.emergency_contact_name"></div>
                            </div>

                            <div class="form-group col-md-6">
                                <label for="ApplicantEmergencyContactPhone">
                                    Téléphone
                                    <span title="Champ requis" v-if="'all' === applicant.accepted_contracts">*</span>
                                </label>
                                <input
                                    type="tel"
                                    v-model="applicant.emergency_contact_phone"
                                    id="ApplicantEmergencyContactPhone"
                                    class="form-control"
                                    :class="{'is-invalid': errors.emergency_contact_phone}"
                                >
                                <div class="invalid-feedback" v-text="errors.emergency_contact_phone"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="step === 6">
                    <div class="card mb-3">
                        <div class="card-body">
                            <div class="row">
                                <h3 class="col-12 mt-0">Mon profil</h3>

                                <div class="form-group col-12">
                                    <label for="ApplicantDescription">Description</label>
                                    <textarea
                                        v-model="applicant.description"
                                        id="ApplicantDescription"
                                        class="form-control"
                                        :class="{'is-invalid': errors.description}"
                                    ></textarea>
                                    <div class="invalid-feedback" v-text="errors.description"></div>
                                </div>

                                <div class="col-12">
                                    <new-position-selection
                                        :position-categories="positionCategories"
                                        :value.sync="applicant.new_positions"
                                        :multiple="true"
                                        label-text="Mes postes"
                                        init-form-input-name="new_positions"
                                        :error-string="errors.new_positions"
                                        :required="'all' === applicant.accepted_contracts"
                                    ></new-position-selection>
                                    <small class="form-text text-muted mt-n2 mb-3">
                                        (3 postes au maximum)
                                    </small>
                                </div>

                                <div class="form-group col-md-6">
                                    <label for="ApplicantSector">
                                        Secteurs
                                        <span title="Champ requis">*</span>
                                    </label>
                                    <v-select
                                        v-model="applicant.sectors"
                                        :options="sectors"
                                        label="name"
                                        :reduce="sector => sector.id"
                                        id="ApplicantSector"
                                        multiple="multiple"
                                        :class="{'is-invalid': errors.sectors}"
                                    >
                                    </v-select>
                                    <div class="invalid-feedback" v-text="errors.sectors"></div>
                                </div>

                                <div class="form-group col-md-6">
                                    <label for="ApplicantPatientTypes">Publics connus</label>
                                    <v-select
                                        v-model="applicant.known_patient_types"
                                        multiple
                                        :options="patientTypes"
                                        label="name"
                                        :reduce="patientType => patientType.id"
                                        id="ApplicantPatientTypes"
                                    >
                                    </v-select>
                                    <div class="invalid-feedback" v-text="errors.known_patient_types"></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="registration-valletoux-card card mb-3" v-if="valletouxMandatory()">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-12 mb-3">
                                    <h5 class="mt-0">Attestation sur l'honneur (à remplir par l'intérimaire)</h5>

                                    <div class="small font-weight-normal">
                                        <p>En vertu du décret, à compter du 1er juillet 2024, la durée minimale d’exercice avant de pouvoir effectuer une mission d’intérim sera désormais fixée à deux ans pour les sages-femmes, infirmiers, aides-soignants, éducateurs spécialisés, assistants de service social, moniteurs-éducateurs et accompagnants éducatifs et sociaux des établissements et services sociaux et médico-sociaux.</p>
                                        <ul>
                                            <li class="d-flex flex-column justify-content-center mb-3">
                                                <span class="list-number">1</span>
                                                <p class="mb-1"><b>Téléchargez les attestations et remplissez-les</b>, soit directement via un outil de lecture de PDF, soit manuellement.</p>
                                                <div class="row">
                                                    <div v-for="document in valletouxDocuments" :key="document.id" class="col-md-6 col-12 p-3">
                                                        <a :href="document.slug ? route('public.document.show', [document.slug]) : '#'" class="btn btn-blue-calypso">{{ document.name }}</a>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <span class="list-number">2</span>
                                                <p>Envoyez <b>l'attestation remplie par vous-même</b> via le champs ci-dessous.</p>
                                                <div class="form-group">
                                                    <label class="d-block">
                                                        <input type="file" @change="updateValletouxCertificationFile" name="valletoux-certification" id="ApplicantValletouxCertification" aria-describedby="ApplicantValletouxCertification" class="d-none" :class="{'is-invalid': errors.valletoux_certification}">
                                                        <span class="col-md-4 col-12 btn bg-rhubarb-pie--lighter mb-3" :class="{'file-uploaded': valletouxCertificationFile}">
                                                            <span v-if="valletouxCertificationFile">
                                                                {{ valletouxCertificationFile.name.substring(0, 20) }}...
                                                                <i class="fa-solid fa-check"></i>
                                                            </span>
                                                            <span v-else>
                                                                Parcourir
                                                                <i class="fa-solid fa-download"></i>
                                                            </span>
                                                        </span>
                                                        <small class="form-text text-muted">
                                                            Taille maximale: 8mo. Formats acceptés: jpg,jpeg,png,pdf,odt,doc,docx,xls,xlsx,ods.
                                                        </small>
                                                        <div class="invalid-feedback" v-text="errors.valletoux_certification"></div>
                                                    </label>
                                                </div>
                                            </li>
                                            <li>
                                                <span class="list-number">2</span>
                                                <p>Envoyez <b>les attestations remplies par vos anciens employeurs</b> via le champs ci-dessous.</p>
                                                <div class="form-group">
                                                    <label class="d-block">
                                                        <input type="file" @change="updateValletouxEmployerCertificationFile" name="valletoux-employer-certification" id="ApplicantValletouxEmployerCertification" aria-describedby="ApplicantValletouxEmployerCertification" class="d-none" :class="{ 'is-invalid': errors.valletoux_employer_certification }" multiple>
                                                        <span class="col-md-4 col-12 btn bg-rhubarb-pie--lighter mb-3" :class="{'file-uploaded': valletouxCertificationFile}">
                                                            <span v-if="valletouxEmployerCertificationFile">
                                                                Fichiers ajoutés
                                                                <i class="fa-solid fa-check"></i>
                                                            </span>
                                                            <span v-else>
                                                                Parcourir
                                                                <i class="fa-solid fa-download"></i>
                                                            </span>
                                                        </span>
                                                        <small class="form-text text-muted">
                                                            Taille maximale: 8mo. Formats acceptés: jpg,jpeg,png,pdf,odt,doc,docx,xls,xlsx,ods.
                                                        </small>
                                                        <div class="invalid-feedback" v-text="errors.valletoux_employer_certification"></div>
                                                    </label>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <h5 class="col-12">Informations complémentaires</h5>

                                <div class="form-group col-md-4">
                                    <div class="custom-control custom-switch pb-2">
                                        <input
                                            id="ApplicantHasDrivingLicence"
                                            type="checkbox"
                                            class="custom-control-input"
                                            v-model="applicant.has_driving_licence"
                                            value="1"
                                        >
                                        <label class="custom-control-label " for="ApplicantHasDrivingLicence">Permis de conduire</label>
                                    </div>
                                </div>

                                <div class="form-group col-md-4">
                                    <div class="custom-control custom-switch pb-2">
                                        <input
                                            id="ApplicantHasVehicle"
                                            type="checkbox"
                                            class="custom-control-input"
                                            v-model="applicant.has_vehicle"
                                            value="1"
                                        >
                                        <label class="custom-control-label " for="ApplicantHasVehicle">Véhiculé</label>
                                    </div>
                                </div>

                                <div class="form-group col-md-4">
                                    <div class="custom-control custom-switch pb-2">
                                        <input
                                            type="checkbox"
                                            class="custom-control-input"
                                            v-model="applicant.allow_weekend_jobs"
                                            value="1"
                                            id="ApplicantAllowWeekendJobs"
                                        >
                                        <label class="custom-control-label" for="ApplicantAllowWeekendJobs">Travail le week-end</label>
                                    </div>
                                </div>

                                <div class="form-group col-md-4">
                                    <div class="custom-control custom-switch pb-2">
                                        <input
                                            type="checkbox"
                                            class="custom-control-input"
                                            v-model="applicant.allow_nightwork"
                                            value="1"
                                            id="ApplicantAllowNightwork"
                                        >
                                        <label class="custom-control-label" for="ApplicantAllowNightwork">Travail de nuit</label>
                                    </div>
                                </div>

                                <div class="form-group col-md-4">
                                    <div class="custom-control custom-switch pb-2">
                                        <input
                                            type="checkbox"
                                            class="custom-control-input"
                                            v-model="applicant.available_quickly"
                                            value="1"
                                            id="ApplicantAvailableQuickly"
                                        >
                                        <label class="custom-control-label" for="ApplicantAvailableQuickly">Mobilisable sous 3h</label>
                                    </div>
                                </div>

                                <div class="form-group col-md-4">
                                    <div class="custom-control custom-switch pb-2">
                                        <input
                                            type="checkbox"
                                            class="custom-control-input"
                                            v-model="applicant.accepts_bathing"
                                            value="1"
                                            id="ApplicantAcceptsBathing"
                                        >
                                        <label class="custom-control-label" for="ApplicantAcceptsBathing">Aide à la toilette acceptée</label>
                                    </div>
                                </div>

                                <div class="form-group col-md-4">
                                    <div class="custom-control custom-switch pb-2">
                                        <input
                                            type="checkbox"
                                            class="custom-control-input"
                                            v-model="applicant.is_student"
                                            value="1"
                                            id="ApplicantIsStudent"
                                        >
                                        <label class="custom-control-label" for="ApplicantIsStudent">Etudiant·e</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card" v-if="step === 7">
                    <div class="card-body">
                        <div class="row">
                            <h3 class="col-12 mt-0">Mes préférences</h3>

                            <div class="form-group col-md-6">
                                <div class="custom-control custom-switch">
                                    <input
                                        type="checkbox"
                                        class="custom-control-input"
                                        v-model="applicant.emailings_optin"
                                        value="1"
                                        id="ApplicantEmailingsOptin"
                                    >
                                    <label class="custom-control-label" for="ApplicantEmailingsOptin">Je souhaite recevoir des suggestions d'offres d'emploi par email de la part d'Ettic</label>
                                </div>
                            </div>

                            <div class="form-group col-md-6">
                                <div class="custom-control custom-switch">
                                    <input
                                        type="checkbox"
                                        class="custom-control-input"
                                        v-model="applicant.trade_union_emailings_optin"
                                        value="1"
                                        id="ApplicantTradeUnionEmailingsOptin"
                                    >
                                    <label class="custom-control-label" for="ApplicantTradeUnionEmailingsOptin">Je souhaite recevoir des notes d'information syndicales par email de la part d'Ettic</label>
                                </div>
                            </div>

                            <hr class="col-12">

                            <div class="form-group col-12">
                                <div class="custom-control custom-switch">
                                    <input
                                        type="checkbox"
                                        class="custom-control-input"
                                        :class="{'is-invalid': errors.rgpd_disclaimer_optin}"
                                        v-model="applicant.rgpd_disclaimer_optin"
                                        id="ApplicantRgpdDisclaimerOptin"
                                        value="1"
                                    >
                                    <label class="custom-control-label" for="ApplicantRgpdDisclaimerOptin">
                                        En cochant cette case, je reconnais avoir pris connaissance et accepte la <a :href="route('public.legal-notice')">politique de confidentialité</a> relative à mes données.
                                        <span title="Champ requis">*</span>
                                    </label>
                                    <div class="invalid-feedback" v-text="errors.rgpd_disclaimer_optin"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex d-md-none justify-content-center mt-3">
                    <i v-for="n in 7" :key="n" class="fa-circle color-blue-calypso" :class="step >= n ?'fa-solid':'fa-regular'"></i>
                </div>
                <div class="col-12 d-flex justify-content-around mt-3">
                    <button v-show="step > 1" class="btn btn-dugong" @click.prevent="previousStep()">Précédent</button>
                    <button v-show="step < 7" class="btn btn-blue-calypso" :disabled="isProcessing" @click.prevent="nextStep()">Suivant</button>
                    <button v-show="step == 7" class="btn btn-blue-calypso" @click.prevent="submit()">Terminer</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import NewPositionSelection from "../Common/NewPositionSelection.vue";
import {Applicant} from "@models";
import axios from "axios";
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import {debounce} from 'lodash';

export default {
    name: 'Registration',
    components: {
        NewPositionSelection,
        vSelect,
    },
    data() {
        return {
            agencies: [],
            applicant: {},
            cities: [],
            csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
            errors: {},
            fieldsByStep: {
                1: ["gender", "first_name", "last_name", "discovered_us_by", "discovered_us_by_custom"],
                2: ["agency_ids", "accepted_contracts"],
                3: ["maiden_name", "birthdate", "nationality_id", "birth_country_id", "birth_department_id", "birthplace", "social_security_number"],
                4: ["situation", "dependant_children", "disabled_worker_status"],
                5: ["phone", "address_line_1", "address_line_2", "address_city_id", "emergency_contact_name", "emergency_contact_phone"],
                6: ["description", "sectors", "new_positions", "known_patient_types", "has_driving_licence", "allow_weekend_jobs", "allow_nightwork", "available_quickly", "has_vehicle", "accepts_bathing", "is_student", "valletoux_certification", "valletoux_employer_certification"],
                7: ["emailings_optin", "trade_union_emailings_optin", "rgpd_disclaimer_optin"],
            },
            lastStep: 1,
            step: 1,
            valletouxCertificationFile: null,
            valletouxEmployerCertificationFile: null,
            isProcessing: false,
        }
    },
    props: {
        initApplicant: {
            type: Object,
            default() {
                return {};
            }
        },
        situations: {
            type: Object,
            default() {
                return {};
            }
        },
        departments: {
            type: Array,
            default: [],
        },
        nationalities: {
            type: Array,
            default: [],
        },
        countries: {
            type: Array,
            default: [],
        },
        patientTypes: {
            type: Array,
            default: [],
        },
        sectors: {
            type: Array,
            default: [],
        },
        positionCategories: {
            type: Array,
            default: [],
        },
        discoverySources: {
            type: Array,
            default: [],
        },
        valletouxDocuments: {
            type: Array,
            default: [],
        },
    },
    created() {
        this.applicant = new Applicant({
            ...this.applicant,
            ...this.initApplicant,
        });

        const { address_city_name } = this.applicant
        if (address_city_name && address_city_name.length > 0) {
            this.searchCity((_) => {}, address_city_name, this)
        }
        this.resetErrors();
    },
    mounted() {
        this.fetchAgencies();
    },
    methods: {
        fetchAgencies() {
            axios.get(route('api.public.agency.index'))
                .then(response => {
                    this.agencies = response.data;
                })
            ;
        },
        flattenObj(obj, parent, res = {}) {
            for (let key in obj) {
                let propName = parent ? parent + '_' + key : key;
                if (typeof obj[key] == 'object') {
                    this.flattenObj(obj[key], propName, res);
                } else {
                    res[propName] = obj[key];
                }
            }

            return res;
        },
        nextStep() {
            if (this.isProcessing){
                return;
            }
            this.isProcessing = true;
            this.validateData(response => {
                    this.step++;

                    if (this.lastStep < this.step) {
                        this.lastStep = this.step;
                    }
            });
        },
        previousStep() {
            this.step--;
        },
        route() {
            return route(...arguments);
        },
        setStep(newStep) {
            if (newStep > this.step) {
                this.validateData(response => {
                    this.step = newStep;
                })
            } else {
                this.step = newStep;
            }
        },
        submit() {
            this.resetErrors();

            let payload = this.createPutPayload(this.applicant);

            if (this.valletouxCertificationFile) {
                payload.append('valletoux_certification', this.valletouxCertificationFile);
            }

            if (this.valletouxEmployerCertificationFile) {
                Array.from(this.valletouxEmployerCertificationFile).forEach((file, index) => {
                    payload.append('valletoux_employer_certification[' + index + ']', file);
                });
            }

            axios.post(
                route('applicant.complete-registration.update'),
                payload,
                {
                    'Content-Type': 'multipart/form-data',
                }
            ).then(response => {
                window.location.href = route('applicant.profile.edit');
            }).catch(this.handleValidationErrors);
        },
        addError(name, message) {
            this.$set(this.errors, name, message);
        },
        removeError(name) {
            delete this.errors[name];
        },
        resetErrors() {
            this.errors = {};
        },
        onCitySearch(search, loading) {
            if (search.length) {
                loading(true);
                this.searchCity(loading, search, this);
            }
        },
        searchCity: debounce((loading, search, vm) => {
            axios.get(
                route('api.applicant.city.index'),
                {
                    params: {q: search}
                }
            ).then(response => {
                vm.cities = response.data.data;
                loading(false);
            })
        }, 350),
        createPutPayload(data) {
            let payload = new FormData();

            payload.append('_method', 'PUT');
            payload.append('payload', JSON.stringify(data));

            return payload;
        },
        validateData(callback) {
            let payload = this.createPutPayload(this.applicant);

            let fields = this.fieldsByStep[this.step];

            this.resetErrors();

            if (this.valletouxCertificationFile) {
                payload.append('valletoux_certification', this.valletouxCertificationFile);
            }

            if (this.valletouxEmployerCertificationFile) {
                Array.from(this.valletouxEmployerCertificationFile).forEach((file, index) => {
                    payload.append('valletoux_employer_certification[' + index + ']', file);
                });
            }

            axios.post(
                route('applicant.complete-registration.update'),
                payload,
                {
                    headers: {
                        'Precognition-Validate-Only': fields.join(),
                        'Precognition': true,
                        'Content-Type': 'multipart/form-data',
                    }
                }
            ).then(callback)
            .catch(this.handleValidationErrors)
            .finally(() => this.isProcessing = false)
        },
        handleValidationErrors(exception) {
            if (422 === exception.response.status) {
                let errors = exception.response.data.errors;

                for (const fieldName in errors) {
                    this.addError(
                        fieldName,
                        errors[fieldName][0]
                    );
                }
            }
        },
        updateValletouxCertificationFile(event) {
            this.valletouxCertificationFile = event.target.files.item(0);
        },
        updateValletouxEmployerCertificationFile(event) {
            this.valletouxEmployerCertificationFile = event.target.files;
        },
        valletouxMandatory() {
            let valletouxPositions = this.positionCategories
                .map(e => e.new_positions.filter(f => f.valletoux_mandatory).map(f => f.id))
                .flat()
            ;

            return this.applicant.accepted_contracts == 'all'
                && this.applicant.new_positions != null
                && this.applicant.new_positions != undefined
                && this.applicant.new_positions.some(position => valletouxPositions.some(valletouxPosition => valletouxPosition == position))
            ;
        },
    },
    computed: {
        bornInFrance() {
            return this.applicant.birth_country_id === this.countries.find(country => country.iso_code === 'FR').id;
        },
        flattenedApplicant() {
            return this.flattenObj(this.applicant);
        }
    }
}
</script>
